<template>
  <div>
    <v-dialog v-model="isDialogVisible" width="500">
      <v-card>
        <v-btn icon @click="closeDialog" class="close-button">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
        <v-card-title> KOLİ </v-card-title>

        <v-card-text>
          <v-text-field
            type="number"
            v-model="koliAdet"
            label="Lütfen KOLİ miktarı giriniz"
            outlined
            dense
            hide-details
            min="0"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="koliEkle"> Ekle </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="d-flex align-center justify-space-between scale-1">
      <v-btn
        depressed
        class="rounded-r-0"
        style="width: 32px; min-width: 32px"
        color="counter"
        light
        @click="minusNumber(BirimRitim)"
      >
        <v-icon small :color="number == 0 ? 'primary' : 'error'">
          {{ icons.mdiMinusThick }}
        </v-icon>
      </v-btn>
      <v-text-field
        ref="input"
        v-model.number="number"
        v-number-only
        :suffix="birim"
        hide-details
        style="min-width: 80px"
        solo
        background-color="counter"
        light
        type="tel"
        flat
        dense
        :disabled="disabled"
        class="right-input rounded-0 font-weight-medium night--text"
     
        @blur="onBlurNumber2(BirimRitim)"
        @focus="$event.target.select()"
      />

      <v-sheet class="d-flex" :color="BgColor">
        <v-btn
          depressed
          class="rounded-l-0"
          :disabled="disabled"
          style="width: 32px; min-width: 32px"
          :color="number == 0 ? 'nightDark' : plantId == '01' ? 'secondary' : 'warning'"
          @click="addNumber(BirimRitim)"
        >
          <v-icon small>
            {{ icons.mdiPlusThick }}
          </v-icon>
        </v-btn>
      </v-sheet>

    <!--   <div>
        <v-btn medium class="ml-4" color="success" style="color: white" @click="openEkleDialog(item)">KOLİ</v-btn>
      </div> -->
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { mdiClose, mdiMinusThick, mdiPlusThick } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default {
  directives: {
    'number-only': {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, '')
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true
          }
          event.preventDefault()
        }
        el.addEventListener('keypress', checkValue)
      },
    },
    'letter-only': {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault()
          }

          return true
        }
        el.addEventListener('keypress', checkValue)
      },
    },
  },
  props: {
    paymCond: {
      type: String,
    },
    bgColor: {
      type: String,
      default: 'secondary',
    },
    uid: {
      default: null,
    },
    plantId: {
      type: String,
      default: '01',
    },

    item: {
      type: Object,
      default: {},
    },

    buttonColor: {
      type: String,
      default: 'white',
    },
    birimRitim: {
      type: Number,
      default: 50,
    },
    birim: {
      type: String,
      required: true   
    },
    miktar: {
      type: Number,
      default: 0,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Boolean,
      default: true,
    },
    light: {
      type: Boolean,
      default: false,
    },
    toplam: {
      type: Number,
      default: 0,
    },
    count: {
      type: Boolean,
      default: true,
    },
    filled: {
      type: Boolean,
      default: true,
    },
    solo: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select', 'refresh'],
  setup(props, { emit }) {
    const disabled = ref(false)
    const number = ref(0)
    const Birim = ref(props.birim)
    const BirimRitim = ref(props.birimRitim)
    const BgColor = ref(props.bgColor)
    const ButtonColor = ref(props.buttonColor)
    const koliAdet = ref('')
    const isDialogVisible = ref(false)
    const refresh = () => {
      emit('refreshBasket')
    }

    const openEkleDialog = item => {
      isDialogVisible.value = true
      console.log(item)

      // store.commit('OPEN_KOLI', true)
    }

    const closeDialog = () => {
      isDialogVisible.value = false
    }

    const koliEkle = () => {
      console.log(koliAdet.value)
      console.log(props.birim_ritim)
      const qty1 = koliAdet.value * props.birimRitim
      number.value = qty1
      addBasket(qty1)
      isDialogVisible.value = false
    }

    watch(
      () => store.state.eklendi,
      () => {
        store.commit('OPEN_KOLI', false)
        // store.commit('KOLI_EKLENDI', false)
      },
    )

    const addBasket = qty => {
      disabled.value = true
      if (qty === 0) {
        const id = props.uid
        const plant = props.plantId

        store.dispatch('app-order/fetchDeleteBasketItem', { id, plant }).then(response => {
          if (response.error) {
          } else {
            refresh()
          }
        })
        setTimeout(() => {
          disabled.value = false
        }, 300)
      } else {
        let veriler = {}

        if (props.paymCond) {
          veriler = { method: 'addToBasket', uid: props.uid, qty, paymCond: props.paymCond, plant: props.plantId }
        } else {
          veriler = {
            method: 'addToBasket',
            uid: props.uid,
            qty,
            plant: props.plantId,
          }
        }
        store.dispatch('app-order/fetchAddToBasket', veriler).then(response => {
          if (response.error) {
          } else {
            refresh()
          }
        })
        setTimeout(() => {
          disabled.value = false
        }, 300)
      }
    }
    var myInterval
    const addNumber = val => {
      number.value += 1 // Number(val) - Number(number.value % val)
      addBasket(number.value)
      select() 

      
    
      
    }

    number.value = Number(props.miktar)

    watch(
      () => props.miktar,
      (first, second) => {
        debugger
        number.value = Number(props.miktar)
        console.log(props.miktar)
     
      },
    )

    const select = () => {
      emit('selected', { number: number.value, uid: props.uid, plantId: props.plantId })
    }

    const onBlurNumber = val => {
      addBasket(number.value)
    }

    //gmz
    const onBlurNumber2 = val => {
      //number.value += Number(val) - Number(number.value % val)
      addBasket(number.value)
      select()
    }

    // eslint-disable-next-line no-return-assign
    const minusNumber = val => {
      if (number.value == 0) {
        return true
      }
      if (number.value < 50) {
        number.value = 0
        addBasket(0)

        select()

        return true
      }

      number.value != 0
        ? (number.value -= 1) //Number(number.value % val) === 0 ? Number(val) : Number(number.value % val)
        : null
      addBasket(number.value)
      select()
    }

    return {
      Birim,
      number,
      isDialogVisible,
      select,
      BgColor,
      ButtonColor,
      koliEkle,
      closeDialog,
      BirimRitim,
      koliAdet,
      openEkleDialog,
      addNumber,
      minusNumber,
      disabled,
      onBlurNumber,
      onBlurNumber2,
      icons: {
        mdiPlusThick,
        mdiMinusThick,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
.scale-1 {
  transform: scale(0.85);
  transform-origin: center right;
}
.completed {
  label,
  .v-text-field__suffix,
  input {
    color: #3b355a !important;
    text-shadow: none !important;
  }
}
.right-input {
  input {
    text-align: right;
  }
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
